async function postData(url = '', data = {}) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: 'POST', // *GET, POST, PUT, DELETE, etc.
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    headers: {
      'Content-Type': 'application/json'
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: 'follow', // manual, *follow, error
    referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

const contactForm = document.getElementById('contact-form')
contactForm.addEventListener("submit", (e) => {
  e.preventDefault()

  const from = contactForm.querySelector('.input.email').querySelector('.input-lineview-content').innerText
  const name = contactForm.querySelector('.input.name').querySelector('.input-lineview-content').innerText
  const subject = contactForm.querySelector('.input.subject').querySelector('.input-lineview-content').innerText
  const content = contactForm.querySelector('.input.body').querySelector('.input-lineview-content').innerText

  postData('https://send-mail.pablogamito.com/', {
    from,
    name,
    subject,
    content
  })
    .then(data => {
      console.log(data); // JSON data parsed by `data.json()` call
    });
});
